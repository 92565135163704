.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1.5rem;
  font-size: 2vh;
}

.nav-links a:hover {
  text-decoration: none;
}

.nav-links a {
  border: 1px solid transparent;
  font-size: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: none;
  color: black;
}

.nav-links label {
  border: 1px solid transparent;
  font-size: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-decoration: none;
  color: black;
}

.nav-links a.active {
  border-bottom: 3px solid #385898;
  color: #385898;
  pointer-events: none;
}

.burger-navlinks {
  float: right;
}

@media (min-width: 1550px) {
  .burger-navlinks {
    display: none;
  }

  .nav-links {
    flex-direction: row;
    margin-left: 1px;
  }

  .nav-links li {
    margin: 0.5rem;
    font-size: 1.2vh;
  }

  .nav-links a {
    color: black;
    font-weight: 500;
    padding: 15px;
    font-size: 130%;
    margin-right: 5px;
    text-decoration: none;
  }

  .nav-links label {
    color: black;
    font-weight: 600;
    margin-top: 7px;
    padding: 15px;
    font-size: 130%;
    margin-right: 5px;
    text-decoration: none;
  }

  .nav-links a.active {
    border-bottom: 3px solid #385898;
    color: #385898;
    padding: 15px;
    pointer-events: none;
  }

  .nav-links a:hover {
    background-color: rgb(241, 238, 238);
    text-decoration: none;
    color: black;
    padding: 15px;
    border-radius: 25px;
  }
}
